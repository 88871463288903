@charset "UTF-8";

/* top slide */
.swiper-container {
    position: relative;
    width: 100%;
    text-align: center;
    .swiper-blind-left,
    .swiper-blind-right {
        display: none;

        @include media(tab) {
            position: absolute;
            top: 0;
            z-index: 5;
            display: block;
            width: 8.35%;
            height: 100%;
            background-color: rgba(0,0,0,0.7);
        }
    }
    .swiper-blind-left {
        left: 0;
    }
    .swiper-blind-right {
        right: 0;
    }
    .swiper-custom-prev,
    .swiper-custom-next {
        display: none;

        @include media(tab) {
            position: absolute;
            top: 0;
            z-index: 100;
            display: block;
            width: 8.35%;
            height: 100%;
            cursor: pointer;
        }
    }
    .swiper-custom-prev {
        right: auto;
        left: 0;
        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            content: url(../../n_img/top/arrow-slide-prev.png);
            transform: translateY(-50%) translateX(-50%);
        }
    }
    .swiper-custom-next {
        right: 0;
        left: auto;
        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            content: url(../../n_img/top/arrow-slide-next.png);
            transform: translateY(-50%) translateX(-50%);
        }
    }
}
.swiper-slide {
    position: relative;
    color: #ffffff;
    img {
        width: 100%;
        height: auto;
    }
    &__sub {
        position: absolute;
        top: 35%;
        left: 50%;
        z-index: 2;
        width: 100%;
        text-shadow: 0 0 15px rgba(40, 40, 40, 1), 0 0 10px rgba(40, 40, 40, 1), 0 0 10px rgba(40, 40, 40, 2);
        letter-spacing: 0.2em;
        font-weight: bold;
        transform: translateX(-50%);

        @include fz(12);
        @include media(tab) {
            top: 30%;
            letter-spacing: 3px;

            @include fz(22);
        }
        @include media(pc) {
            top: 35%;

            @include fz(30);
        }
    }
    &__text {
        position: absolute;
        top: 60%;
        left: 50%;
        z-index: 2;
        width: 100%;
        text-align: center;
        text-shadow: 0 0 5px rgba(40, 40, 40, 8), 0 0 10px rgba(40, 40, 40, 8), 0 0 10px rgba(40, 40, 40, 6);
        transform: translateX(-50%);

        @include fz(10);
        @include media(tab) {
            top: 55%;

            @include fz(14);
        }
        @include media(pc) {
            top: 55%;

            @include fz(19);
        }
        span {
            display: block;
            padding: 0 2em;

            @include media(tab) {
                margin: 0;
            }
        }
    }
}
.swiper-pagination {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
}
.swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0 0.3em;
}
.swiper-pagination-bullet-active {
    background: $color-key;
    opacity: 1;
}