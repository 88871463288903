@charset "UTF-8";

/* top page */
.p-top {
    &__works {
        display: flex;
        margin: 1em;

        @include media(tab) {
            display: block;
        }
        @include media(pc) {
            margin: 1em 0;
        }
        &Area {
            position: relative;
            width: calc((100% / 2) - 1em);
            margin-left: 2em;
            padding-bottom: 1.5em;

            @include media(tab) {
                width: 100%;
                margin: 4em 0 0 0;
                padding-bottom: 0;
            }
            @include media(pc) {
                margin: 6em 0 0 0;
            }
        }
        &Area::after {
            display: block;
            clear: both;
            overflow: hidden;
            height: 1px;
            content: "";
        }
        &Area:first-child {
            margin-left: 0;
        }
        &Item {
            @include media(tab) {
                width: 370px;
            }
            @include media(pc) {
                margin: 2em 0 0 0;
            }
        }
        &Area:nth-child(odd) {
            .p-top__worksItem {
                @include media(tab) {
                    float: left;
                }
            }
        }
        &Area:nth-child(even) {
            .p-top__worksItem {
                @include media(tab) {
                    float: right;
                }
            }
        }
        &Title {
            position: relative;
            padding-left: 2.8em;

            @include fz(15);
            @include media(tab) {
                padding-left: 2.5em;
                font-weight: normal;

                @include fz(24);
            }
            &::before {
                position: absolute;
                top: 0.8em;
                left: 0;
                width: 25px;
                height: 3px;
                padding-left: 2em;
                background-color: $color-key;
                content: "";
                transform: translateY(-50%);

                @include media(tab) {
                    width: 38px;
                }
            }
            strong {
                display: none;

                @include media(tab) {
                    display: block;
                    margin-top: 0.5em;
                    color: #727171;
                    font-weight: normal;

                    @include fz(15);
                }
            }
        }
        &Text {
            margin-top: 0.3em;

            @include fz(12);
            @include media(tab) {
                margin: 2em 0 2em 3.8em;

                @include fz(16);
            }
        }
        &Img {
            margin: 0.5em 0;

            @include media(tab) {
                width: calc(100% - 370px);
                margin: 0;
            }
        }
        &Area:nth-child(odd) {
            .p-top__worksImg {
                @include media(tab) {
                    float: right;
                    padding-left: 3em;
                }
            }
        }
        &Area:nth-child(even) {
            .p-top__worksImg {
                @include media(tab) {
                    float: left;
                    padding-right: 3em;
                }
            }
        }
        &Link {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;

            @include media(tab) {
                position: relative;
                margin-left: 3.8em;
            }
        }
    }
    &__cmenu {
        display: flex;
        margin-top: 1.5em;

        @include media(tab) {
            margin-top: 4em;
        }
        @include media(pc) {
            margin-top: 6em;
        }
        &Img {
            position: relative;
            width: calc(100% / 2);
            height: 100px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            text-align: center;
            transition: background 0.5s ease;

            @include media(tab) {
                height: 260px;
            }
            @include media(pc) {
                background-size: 100%;
            }
            &:first-child {
                background-image: url(../../n_img/top/m01.jpg);
            }
            &:last-child {
                background-image: url(../../n_img/top/m02.jpg);
            }
            &:hover {
                @include media(pc) {
                    background-size: 110%;
                }
            }
        }
        &Link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            display: block;
            background-color: rgba(0, 0, 0, 0.6);
            content: "";
            text-decoration: none;
            transition: background 0.5s ease;
            &:hover {
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
        &Title {
            position: absolute;
            top: 50%;
            left: 10%;
            width: 100%;
            color: #ffffff;
            text-align: left;
            font-family: Arial, Helvetica, sans-serif;
            transform: translateY(-50%);

            @include media(tab) {
                top: 50%;
                left: 50%;
                text-align: center;
                transform: translateY(-50%) translateX(-50%);
            }

            strong {
                overflow-wrap: break-word;
                @include fz(11);
                @include media(tab) {
                    @include fz(25);
                    overflow-wrap: break-word;
                }
            }
            strong.eng {
                display: block;

                overflow-wrap: break-word;
                @include fz(11);
                @include media(tab) {
                    @include fz(25);
                    overflow-wrap: break-word;
                }
                img {
                    display: block;
                    margin: 0.5em 0 0 0;

                    @include media(tab) {
                        display: inline;
                        margin: 0 0 0 1em;
                        vertical-align: middle;
                    }
                }
            }
            h2 {
                display: block;
                font-weight: normal;

                @include fz(14);
                @include media(tab) {
                    @include fz(20);
                }
                img {
                    margin-left: 1em;
                    vertical-align: middle;
                }
            }
        }
    }
    &__news {
        margin: 1em 1em 0 1em;

        @include media(tab) {
            margin: 3em 1em 0 1em;
        }
        @include media(pc) {
            margin: 5em 1em 0 1em;
        }
        &Title {
            position: relative;
            padding-left: 2.5em;

            @include fz(15);
            @include media(tab) {
                font-weight: normal;

                @include fz(24);
            }
            strong {
                display: none;

                @include media(tab) {
                    display: inline-block;
                    margin-left: 1em;
                    color: #727171;
                    font-weight: normal;

                    @include fz(15);
                }
            }
            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                width: 25px;
                height: 3px;
                background-color: $color-key;
                content: "";
                transform: translateY(-50%);

                @include media(tab) {
                    width: 38px;
                }
            }
        }
        &Detail {
            @include media(tab) {
                margin-top: 3em;
            }
            dl {
                padding: 0.5em 0;
                border-bottom: 1px solid #efefef;

                @include media(tab) {
                    display: flex;
                    margin: 0 1em;
                    padding: 1em;
                }
                @include media(pc) {
                    margin: 0 3em;
                }
                dd {
                    flex: 1 0 200px;
                    margin-top: 0.5em;

                    @include fz(12);
                    @include media(tab) {
                        margin-top: 0;

                        @include fz(15);
                    }
                }
            }
            a {
                display: block;
                color: #000000;
                text-decoration: none;
                transition: background 0.3s;
                &:hover {
                    background-color: #f0f0f0;
                }
            }
        }
        &Date {
            @include fz(12);
            @include media(tab) {
                width: 42%;

                @include fz(15);
            }
            @include media(pc) {
                width: 38%;
            }
            span {
                display: inline-block;
                min-width: 90px;
                margin-right: 1em;

                @include media(tab) {
                    min-width: 100px;
                    margin-right: 2em;
                }
                @include media(pc) {
                    margin-right: 3em;
                }
            }
            span.eng {
                min-width: 110px;

                @include media(tab) {
                    min-width: 150px;
                    margin-right: 2em;
                }
                @include media(pc) {
                    margin-right: 3em;
                }
            }
        }
        &Link {
            margin-top: 1em;
            text-align: right;

            @include media(tab) {
                margin-top: 3em;
            }
        }
    }
}