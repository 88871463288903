//表示切替用
.u-display--sp {
    @media screen and (min-width: #{$layout-width-smartphone}) {
        display: none !important;
    }
}
.u-display--tab {
    @media screen and (min-width: #{$layout-width-container}) {
        display: none !important;
    }
}
.u-display--pc {
    @media screen and (max-width: #{$layout-width-container - 1}) {
        display: none !important;
    }
}
.u-none--sp {
    @media screen and (max-width: #{$layout-width-smartphone - 1}) {
        display: none !important;
    }
}