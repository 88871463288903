@charset "UTF-8";
/* 全ページレイアウト */
.l-container {
    padding-top: 55px;

    @include media(tab) {
        padding-top: 120px;
    }
    @include media(pc) {
        width: $layout-width-container;
        margin: 0 auto;
        padding-top: 102px;
    }
}
.l-header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    transition: top 0.3s;

    @include media(tab) {
        left: 0;
        display: block;
        height: 125px;
        background-color: #ffffff;
        transition: transform 0.5s;
        transform: translateY(0);
    }
    @include media(pc) {
        height: 111px;
    }
    &.return {
        @include media(tab) {
            transform: translateY(-65px);
        }
        @include media(pc) {
            transform: translateY(-45px);
        }
        .p-header__ttl {
            @include media(pc) {
                padding-top: 1.5em;
            }
        }
    }
}
.l-contents {
    margin: 0 0 1em 0;

    @include media(pc) {
        width: 1020px;
        margin: 0 auto 5em auto;
    }
}
.l-footer {
    background-color: #7f7f7f;
    color: #ffffff;
}