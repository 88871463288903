
@charset "UTF-8";

/* footer */
.p-footer {
    padding: 0.5em 0;

    @include media(tab) {
        padding: 2em 0;
    }
    @include media(pc) {
        width: $layout-width-container;
        margin: 0 auto;
        padding: 3em 0 2em 0;
    }
    &__nav {
        display: none;

        @include media(tab) {
            display: flex;
            justify-content: center;
        }
        &Item {
            @include media(tab) {
                margin: 0 0.5em;
                list-style: none;
            }
            @include media(pc) {
                margin: 0 1.5em;
            }
            a {
                color: #ffffff;
                text-decoration: none;
                transition: color 0.3s;
                &:hover {
                    color: #c7c7c7;
                }
            }
        }
        &Sub {
            @include media(tab) {
                margin-top: 1em;
                padding: 0.8em 0.5em;
                font-weight: bold;
            }
        }
        &-secondary {
            @include media(tab) {
                min-width: 100px;
                padding: 1em 0.5em;
                border-top: 1px solid #ffffff;
                list-style: none;
                line-height: 2.2;

                @include fz(14);
            }
            @include media(pc) {
                min-width: 150px;
            }
        }
    }
    &__Link {
        text-align: center;

        @include media(tab) {
            margin: 2em 3em 0 0;
            text-align: right;
        }
        @include media(pc) {
            margin: 4em 3em 0 0;
        }
    }
    &__copyright {
        margin-top: 0.5em;
        text-align: center;

        @include fz(11);
        @include media(tab) {
            margin-top: 2em;
        }
    }
}