@charset "UTF-8";

/* link btn */
.p-btn__link {
    position: relative;
    display: block;
    padding: 0.2em 1em;
    background-image: url(../../n_img/top/arrow-small.png);
    background-position: center right 0.5em;
    background-repeat: no-repeat;
    color: #ffffff;
    text-decoration: none;

    @include fz(12);
    @include media(tab) {
        display: inline-block;
        min-width: 220px;
        padding: 0.8em 2em;
        background-image: url(../../n_img/top/arrow.png);
        background-position: center right 1em;

        @include fz(17);
    }
    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: #333333;
        content: "";
        transition: transform 0.3s;
        transform: scale(0, 1);
        transform-origin: right top;
    }
    &:hover::before {
        transform: scale(1, 1);
        transform-origin: left top;
    }
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2;
        background-color: $color-key;
        content: "";
    }
}
.p-btn__link--line {
    position: relative;
    display: inline-block;
    padding-right: 3em;
    background-image: url(../../n_img/top/arrow-menu.png);
    background-position: center right;
    color: #000000;
    text-decoration: none;

    @include fz(12);
    @include media(tab) {
        padding-right: 2em;

        @include fz(17);
    }
    &::before {
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background: $color-key;
        content: "";
        transition: transform 0.3s;
        transform: scale(0, 1);
        transform-origin: right top;
    }
    &:hover::before {
        transform: scale(1, 1);
        transform-origin: left top;
    }
}
.p-btn__link--linewhite {
    position: relative;
    display: inline-block;
    padding-right: 2em;
    background-image: url(../../n_img/top/arrow-small.png);
    background-position: center right;
    color: #ffffff;
    text-decoration: none;

    @include fz(11);
    &::before {
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #ffffff;
        content: "";
        transition: transform 0.3s;
        transform: scale(0, 1);
        transform-origin: right top;
    }
    &:hover::before {
        transform: scale(1, 1);
        transform-origin: left top;
    }
}

/* tag btn */
.p-btn__tag {
    padding: 0.1em 1em;
    border-radius: 20px;
    color: #ffffff;
    text-align: center;

    @include fz(11);
    @include media(tab) {
        @include fz(14);
    }
}
.p-btn__tag--blue {
    background-color: $color-key;
}
.p-btn__tag--orange {
    background-color: $color-orange;
}
.p-btn__tag--green {
    background-color: $color-green;
}
.p-btn__tag--gray {
    background-color: $color-gray;
}