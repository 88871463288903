@charset "UTF-8";
html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;

    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overscroll-behavior-y: none;
}
body {
    overflow: hidden;
    width: 100%;
    letter-spacing: 1px;
    font-family: $font-family-gothic;
    line-height: 1.6;

    @include fz(16);
}
img {
    max-width: 100%;
    height: auto;

    -webkit-backface-visibility: hidden;
}