@charset "UTF-8";

/* header */
.p-header {
    caret-color: transparent;
    @include media(tab) {
        padding: 0.5em 0 0 0;
    }
    @include media(pc) {
        display: flex;
        width: $layout-width-container;
        margin: 0 auto;
        padding: 1.4em 2em 0 2em;
    }
    &__ttl {
        position: relative;
        z-index: 1000;
        width: 100%;
        height: 55px;
        padding: 0 0.5em;
        background-color: #ffffff;

        @include media(tab) {
            width: auto;
            height: auto;
            margin-bottom: -2em;
            padding: 0 0.5em;
            background-color: transparent;
        }
        @include media(pc) {
            margin-bottom: 0;
            transition: padding-top 0.5s;
        }
    }
    &__logo {
        @include media(tab) {
            width: 310px;
        }
        img {
            width: 220px;
            margin-bottom: 0.2em;

            @include media(tab) {
                width: 300px;
                padding-top: 0.2em;
            }
        }
    }
    &__nav {
        @include media(pc) {
            -webkit-box-flex: 1;
            -webkit-flex: 1 0 400px;
               -moz-flex: 1 0 400px;
                    flex: 1 0 400px;
        }
    }
}