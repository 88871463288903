@charset "UTF-8";
// font-size rem
@mixin fz($size: 16) {
    font-size: ($size / 10) + rem;
}
// position-center
@mixin center($direction) {
    @if $direction==xy {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } @else if $direction==x {
        left: 50%;
        transform: translateX(-50%);
    } @else if $direction==y {
        top: 50%;
        transform: translateY(-50%);
    }
}
// icon-img
@mixin icon-img($file, $width, $height) {
    width: $width;
    height: $height;
    background: url($file) no-repeat 50% 50%;
    background-size: $width $height;
}
// icon-arrow
@mixin icon-arrow($width, $bold, $color) {
    position: absolute;
    display: inline-block;
    width: #{$width}px;
    height: #{$width}px;
    border-width: 0 #{$bold}px #{$bold}px 0;
    border-style: solid;
    border-color: $color;
    content: "";
}