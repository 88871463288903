@charset "UTF-8";

@include media(sp) {
    .p-nav__toggle {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1000;
        width: 35px;
        height: 33px;
        background-color: $color-key;
        cursor: pointer;
    }
    .p-nav__toggle span {
        position: absolute;
        left: 50%;
        width: 18px;
        height: 2px;
        border-radius: 4px;
        background-color: #ffffff;
        transform: translateX(-50%);
    }
    .p-nav__toggle,
    .p-nav__toggle span {
        display: inline-block;
        box-sizing: border-box;
        transition: all 0.5s;
    }
    .p-nav__toggle span:nth-of-type(1) {
        top: 8px;
    }
    .p-nav__toggle span:nth-of-type(2) {
        top: 15px;
    }
    .p-nav__toggle span:nth-of-type(3) {
        top: 22px;
    }
    .p-nav__toggle.active span:nth-of-type(1) {
        transform: translateY(7px) translateX(-50%) rotate(-45deg);
    }
    .p-nav__toggle.active span:nth-of-type(2) {
        left: 50%;
        opacity: 0;
        animation: active-btn05-bar02 0.8s forwards;
    }
    @keyframes active-btn05-bar02 {
        100% {
            height: 0;
        }
    }
    .p-nav__toggle.active span:nth-of-type(3) {
        transform: translateY(-7px) translateX(-50%) rotate(45deg);
    }
    .p-nav {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        visibility: hidden;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        max-height: 0;
        height: 100%;
        background-color: #ffffff;
        font-size: 1.8rem;
        transition: all 0.8s ease;

        &__sub {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 55px;
            padding: 0.5em 0;
            background-color: $color-key;
            list-style: none;

            @include fz(13);
            li a {
                display: block;
                padding: 0.3em 2em;
                color: #ffffff;
                text-decoration: none;
            }
            li:first-child a {
                border-right: solid 1px #ffffff;
                background-image: url(../../n_img/top/icon-english-sp.png);
                background-position: center left;
                background-repeat: no-repeat;
            }
            li:last-child a {
                background-image: url(../../n_img/top/icon-america-sp.png);
                background-position: center right;
                background-repeat: no-repeat;
            }
        }
        &__item a {
            display: block;
            padding-left: 4em;
            border-bottom: 1px solid #dcdddd;
            background-image: url(../../n_img/top/arrow-menu.png);
            background-position: center left 1em;
            background-repeat: no-repeat;
            color: #000000;
            text-decoration: none;
            line-height: 3.5;

            @include fz(13);
        }
        &__secondary {
            display: none;
        }
        &__secondary a {
            background-image: none;
        }
    }
    .p-nav__item--sp {
        pointer-events: none;
    }

    /* open */
    .open {
        overflow: hidden;
    }
    .open .p-nav {
        visibility: visible;
        max-height: 999px;
    }
}
@include media(tab) {
    .p-nav {
        margin-right: 0.5em;

        @include media(pc) {
            margin-right: 0;
        }
        ul {
            list-style: none;
        }
        &__toggle {
            display: none;
        }
        &__sub {
            display: flex;
            justify-content: flex-end;

            @include fz(15);
            li a {
                margin-left: 1.2em;
                color: #595757;
                text-decoration: none;
                &:hover {
                    color: $color-key;
                }
            }
            li:first-child a {
                padding-left: 1.2em;
                background-image: url(../../n_img/top/icon-english-pc.png);
                background-position: center left;
                background-repeat: no-repeat;
            }
            li:last-child a {
                padding-right: 1.2em;
                background-image: url(../../n_img/top/icon-america-pc.png);
                background-position: center right;
                background-repeat: no-repeat;
            }
        }
        &__list {
            display: flex;
            justify-content: flex-end;
            margin-top: 0.7em;

            @include fz(15);
            @include media(pc) {
                margin-top: 0.9em;
            }
        }
        &__item> a {
            position: relative;
            display: block;
            padding: 0.6em 0.8em 1.2em 0.8em;
            background-color: #ffffff;
            color: #000000;
            text-decoration: none;
            &::after {
                position: absolute;
                right: 10px;
                bottom: 8px;
                left: 10px;
                height: 2px;
                background: #ffffff;
                content: "";
            }
        }
        &__item:hover> a {
            @include media(pc) {
                background-color: $color-key;
                color: #ffffff;
            }
        }
        &__item.open> a {
            background-color: $color-key;
            color: #ffffff;
        }

        &__secondary {
            position: absolute;
            top: 125px;
            left: 0;
            z-index: 999;
            display: none;
            width: 100%;
            padding: 1em 0.5em;
            background-color: $color-key;

            @include media(pc) {
                top: 110px;
                display: block;
                overflow: hidden;
                max-height: 0;
                padding: 0;
                opacity: 0.8;
                transition: padding 0.3s ease-out, opacity 0.3s;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                width: auto;
                margin: 0;

                @include media(pc) {
                    width: 1050px;
                    margin: 0 auto;
                }
            }
            li a {
                position: relative;
                margin-right: 2.5em;
                padding: 0.5em 0 0.5em 2em;
                background-image: url(../../n_img/top/arrow-small.png);
                background-position: center left;
                background-repeat: no-repeat;
                color: #ffffff;
                text-decoration: none;
                &::after {
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #ffffff;
                    content: "";
                    transition: transform 0.3s;
                    transform: scale(0, 1);
                    transform-origin: right top;
                }
                &:hover::after {
                    @include media(pc) {
                        transform: scale(1, 1);
                        transform-origin: left top;
                    }
                }
            }
        }
        .p-nav__item:hover &__secondary {
            @include media(pc) {
                max-height: 9999px;
                padding: 1.6em 0;
                opacity: 1;
            }
        }
    }
    .p-nav__item--sp {
        pointer-events: none;
    }
}
@include media(pc) {
    .p-nav__item--sp {
        pointer-events: auto;
    }
}