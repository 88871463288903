@charset "UTF-8";

// =============================
// サイト基本設定
// =============================

// ベース色
$color-corp: #3950e5;
$color-key: #00479d;
$color-orange: #ffc000;
$color-green: #8fc31f;
$color-gray: #7f7f7f;

// フォント
$font-family-minco: "游明朝体", "Yu Mincho", yumincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝",
	"MS PMincho", serif;
$font-family-gothic: "Meiryo UI", Meiryo, "メイリオ", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "MS Pゴシック", "MS PGothic", sans-serif;

// レイアウト幅
$layout-width-smartphone: 768px;
$layout-width-container: 1200px;

// ブレークポイント
$breakpoints: (
	"sp": "screen and (max-width: #{$layout-width-smartphone - 1})",
	"tab": "screen and (min-width: #{$layout-width-smartphone})",
	"pc": "screen and (min-width: #{$layout-width-container})",
);
@mixin media($breakpoint: sp) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}